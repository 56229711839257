/**
 * 配置 filter 别名与 url 名称的转换关系
 */
import { houseSearchType } from '@lib/config'

const residential = {
  special: {
    price: 'prn',
    bedroom: 'bern',
    bathroom: 'barn',
    carspace: 'pkn',
    category: 'ptn',
  },
  alias: {
    'open-home': 'oh',
    'price-method': 'sm',
    listed: 'lt',
    'floor-area': 'fa',
    'land-area': 'la',
    'decade-built': 'yb',
    'title-types': 'tt',
    'rating-valuation': 'cr',
    'unitary-plan': 'utz',
    'school-zone': 'sz',
    category: 'ptn',
  }
}

const rent = {
  special: {
    price: 'prrn',
    bedroom: 'bern',
    bathroom: 'barn',
    carspace: 'pkn',
    category: 'ptrn',
  },
  alias: {
    listed: 'lt',
    'decade-built': 'yb',
    'school-zone': 'sz',
  }
}

const newHomes = {
  special: { ...residential.special },
  alias: { ...residential.alias }
}

const estimate = {
  special: {
    price: 'crn'
  },
  alias: {
    'land-area': 'la',
    'decade-built': 'yb',
    'title-types': 'tt',
    'rating-valuation': 'cr',
    'unitary-plan': 'utz',
    'school-zone': 'sz',
  }
}

const development = estimate
// const development = {
//   special: { ...estimate.special },
//   alias: { ...estimate.alias }
// }

const sold = {
  special: { ...residential.special },
  alias: {
    'floor-area': 'fa',
    'land-area': 'la',
    'decade-built': 'yb',
    'title-types': 'tt',
    'rating-valuation': 'cr',
    'school-zone': 'sz',
  }
}

const rural = {
  special: {
    price: 'prn',
    bedroom: 'bern',
    bathroom: 'barn',
    carspace: 'pkn',
    category: 'ptrun',
  },
  alias: {
    'price-method': 'sm',
    listed: 'lt',
    'land-area': 'lar',
  }
}

const commercialForSale = {
  special: {
    price: 'prcsn',
    category: 'ptcsn',
  },
  alias: {
    'price-method': 'sm',
    listed: 'lt',
    'land-area': 'lacn',
    'floor-area': 'fac',
  }
}

const commercialForLease = {
  special: {
    price: 'prcln',
    category: 'ptcln',
  },
  alias: {
    listed: 'lt',
    'land-area': 'lacn',
    'floor-area': 'fac',
  }
}

export default {
  [houseSearchType.residential]: residential,
  [houseSearchType.rent]: rent,
  [houseSearchType.newHomes]: newHomes,
  [houseSearchType.estimate]: estimate,
  [houseSearchType.development]: development,
  [houseSearchType.sold]: sold,
  [houseSearchType.rural]: rural,
  [houseSearchType.commercialForSale]: commercialForSale,
  [houseSearchType.commercialForLease]: commercialForLease,
}
