import { filterConfig, filterMeta } from '@comp/page/common/filter/combine/filter-modal/meta'
import { getPriceIds } from '@comp/page/common/filter/combine/filter-modal/lib'
import { omit, keys, forEach, isArray, values } from 'lodash-es'
import { houseSearchType, typeIds } from '@lib/config'
import { notFound } from 'next/navigation'
import { isValid } from '@lib/utils'
import { parseAndCheckV2, getApiSearchParam, modeType } from './lib'
import * as config from './config'

function getParam(rules, slug = '', limit = 20) {
  let searchParam = null
  let apiParam = null
  
  try{
    const { param, isPass } = parseAndCheckV2(slug, rules)
    if(!isPass) throw Error('invalid')

    searchParam = param
    apiParam = getApiSearchParam(param, rules, limit)
  } catch(err) {
    err.message === 'invalid' && notFound()
  }
  
  return {
    searchParam,
    apiParam,
  }
}

/**
 * 获取房源类型 filter 值
 */
function getListingModeValue({ searchParam, alias }) {
  let res
  const aliasValues = filterMeta[alias]?.values

  forEach(modeType, (key) => {
    const categoryValue = isArray(searchParam[key]) ? searchParam[key] : []

    if(alias && categoryValue.length > 0) {
      const tmp = []
      forEach(categoryValue, (k) => {
        isValid(aliasValues?.[k]) && tmp.push(aliasValues[k])
      })

      if(tmp.length > 0) {
        res = `${alias}_${tmp.join(',')}`
      }
    }
  })
  
  return res
}

/**
 * 获取房源列表接口需要的参数
 * 将 url中的参数，根据配置文件转换成 filter
 */
function getListingApiParam({ type, apiParam, searchParam }) {
  const filterCfg = filterConfig[type] || {}
  let res = {
    typeId: typeIds[type],
    ...apiParam,
    ...(type === houseSearchType.newHomes ? { newHouse: 1 } : null),
  }

  // 周边成交
  if(apiParam.surrounding) {
    res.surrounding = '1'
  } else {
    res = omit(res, ['surrounding'])
  }

  // filters
  const filters = []
  const needDeletedProps = []

  // 处理url中filters过来的参数
  forEach(
    isArray(searchParam.filters) ? searchParam.filters : [],
    (item) => {
      const alias = filterCfg.alias?.[item.label] || item.label
      filters.push(`${alias}_${item.value}`)
    }
  )

  // 处理配置文件中，需要转换成filter的数据
  forEach(keys(filterCfg.special || {}), (specialKey) => {
    const alias = filterCfg.special[specialKey]
    const prop = config.houseSearchRules[specialKey]?.prop || specialKey
    const val = apiParam[prop]

    needDeletedProps.push(prop)
    
    if(specialKey === 'category') {
      const cv = getListingModeValue({ searchParam, alias })
      cv !== undefined && filters.push(cv)
    } else if(specialKey === 'price') {
      const priceFilterLabel = getPriceIds(alias, val, filterMeta[alias]?.names)
      priceFilterLabel && filters.push(priceFilterLabel)
      // 
    } else if(isValid(val)) {
      filters.push(`${alias}_${val}`)
    }
  })

  if(filters.length > 0) {
    res.filter = filters.join('_')
  }
  
  return omit(res, [
    'page',
    'filters',
    ...values(modeType),
    ...needDeletedProps
  ])
}

/**
 * 获取列表接口需要的参数
 */
export function listingApi(type, searchParam) {
  const apiParam = {
    ...getApiSearchParam(searchParam, config.houseSearchRules),
    typeId: typeIds[type],
  }
  
  return getListingApiParam({ type, apiParam, searchParam })
}

/**
 * 解析 slug 并返回一个对象与接口请求所用到的参数
 */
export function listing(slug, type, limit) {
  const { searchParam, apiParam } = getParam(config.houseSearchRules, slug, limit)
  return {
    searchParam: { ...searchParam, type },
    apiParam: getListingApiParam({ type, apiParam, searchParam }),
  }
}

/**
 * 返回 listing filter接口所需要的参数
 */
export function getRecommendParam(apiParam) {
  return omit(apiParam, ['limit', 'offset', 'tagId', 'order'])
}

/**
 * 中介列表参数
 */
export function agents(slug) {
  const { searchParam, apiParam } = getParam(config.agentsRules, slug)
  const suburbObj = searchParam.suburb?.[0]
  const tmp = { ...omit(apiParam, ['filters', 'page']) }

  if(isArray(searchParam.filters)) {
    searchParam.filters.forEach(({ label, value }) => {
      tmp[label] = value
    })
  }
 
  return {
    searchParam,
    apiParam: tmp,
    suburbName: suburbObj.label,
    suburbId: suburbObj.value,
    autoCompleteValue: suburbObj.label
      ? [{ label: suburbObj.label, value: suburbObj.value }] 
      : []
  }
}

/**
 * 建筑汇产品列表参数
 */
export function products(slug) {
  return getParam(config.productSpecSearchRules, slug)
}

/**
 * 建筑汇案例列表参数
 */
export function cases(slug) {
  return getParam(config.productSpecCaseRules, slug)
}

/**
 * 建筑汇直播公开课列表参数
 */
export function psLive(slug) {
  return getParam(config.productSpecLiveRules, slug)
}
