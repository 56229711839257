import { forEach, isArray, omit, values } from 'lodash-es'
import * as houseRoute from '@lib/route/house'
import * as psRoute from '@lib/route/productspec'
import * as agentRoute from '@lib/route/agent'
import * as config from './config'
import { stringifyToUrl, modeType } from './lib'

/**
 * 获取房源类型
 */
export function getListingCategory(param) {
  let name
  let value = []

  forEach(modeType, (key) => {
    if(isArray(param[key])) {
      value = param[key]
      name = key
    }
  })

  return { name, value }
}

export function listingBase(param, isList = false) {
  let tmp = omit(param, values(modeType))
  const { name, value } = getListingCategory(param)

  // 如果不搜索周边, 则删除这个参数
  if(!param.surrounding) {
    tmp = omit(tmp, ['surrounding'])
  }

  // 如果类型没有值，默认给一个空数组
  if(isList) {
    tmp[modeType.listing] = value
  } else {
    tmp[name || modeType.listing] = value
  }

  // 添加分页标记
  if(tmp.page === undefined) tmp.page = 1

  return stringifyToUrl(tmp, config.houseSearchRules)
}

/**
 * 生成房源列表url
 * @param { Boolean } isMapListing 是否跳转到地图列表
 */
export function listing(
  type, 
  param, 
  locale = undefined, 
  isMapListing = false,
) {
  const pathname = listingBase(param, isMapListing)
  return isMapListing
    ? houseRoute.seasrchMap(type, pathname, locale)
    : houseRoute.search(type, pathname, locale)
}

/**
 * 生成默认的房源列表
 */
export function listingDefault(type, locale, isMapListing) {
  return listing(type, { [modeType.listing]: [], page: 1 }, locale, isMapListing)
}

/**
 * 生成默认的房源列表
 */
export function listingAI(type, param, locale) {
  const pathname = listingBase(param, true)
  return houseRoute.searchAI(type, pathname, locale)
}

/**
 * 生成中介列表url
 */
export function agents(param, locale) {
  return agentRoute.agents(stringifyToUrl(param, config.agentsRules), locale)
}

/**
 * 生成建筑汇产品列表url
 */
export function products(param, locale) {
  return psRoute.search(
    stringifyToUrl(param, config.productSpecSearchRules),
    locale
  )
}

/**
 * 生成建筑汇Case列表url
 */
export function cases(param, locale) {
  return psRoute.cases(
    stringifyToUrl(param, config.productSpecCaseRules),
    locale
  )
}

/**
 * 生成建筑汇Case列表url
 */
export function lives(param, locale) {
  return psRoute.lives(
    stringifyToUrl(param, config.productSpecLiveRules),
    locale
  )
}
